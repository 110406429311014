import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';
import { GatewayProvider, GatewayDest } from 'react-gateway';

import { getIsPostPageBundle } from '@wix/communities-blog-client-common';

import { isEditor } from '../../store/basic-params/basic-params-selectors';
import AppWrapper from '../app-wrapper';
import DemoMessageRoot from '../../containers/demo-message-root';
import AccessibilityListener from '../accessibility-listener';
import { isInWix } from '../../services/is-in-wix';
import styles from './app.scss';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';

export class AppMobile extends Component {
  componentDidMount() {
    if (!isInWix()) {
      document.documentElement.classList.add('enable-scroll');
    }
  }

  render() {
    const {
      createdWithResponsiveEditor,
      main,
      modalRoot,
      messageRoot,
      children,
      mobileHeader,
      mobileMenu,
    } = this.props;
    return (
      <GatewayProvider>
        <AppWrapper className={classNames(styles.app, 'app-mobile', 'blog-background-color')}>
          <AccessibilityListener />
          {messageRoot}
          <DemoMessageRoot />
          {modalRoot}
          <div id="content-wrapper" className={classNames(styles.scrollable, 'app-mobile__scrollable')}>
            {mobileHeader &&
              React.cloneElement(mobileHeader, {
                menu: mobileMenu,
                hideNavBar: createdWithResponsiveEditor,
              })}
            {main && React.cloneElement(main, { children })}
          </div>
          <GatewayDest name="footer" />
          <GatewayDest name="floating-action-button" />
          <GatewayDest name="tooltip" />
        </AppWrapper>
      </GatewayProvider>
    );
  }
}

AppMobile.propTypes = {
  categories: PropTypes.array,
  createdWithResponsiveEditor: PropTypes.bool,
  children: PropTypes.node,
  isEditor: PropTypes.bool,
  isPostPageBundle: PropTypes.bool,
  main: PropTypes.node,
  modalRoot: PropTypes.node.isRequired,
  messageRoot: PropTypes.node.isRequired,
  mobileHeader: PropTypes.node,
  mobileMenu: PropTypes.node,
};

const mapRuntimeToProps = state => ({
  isEditor: isEditor(state),
  categories: state.categories,
  createdWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
  isPostPageBundle: getIsPostPageBundle(state),
});

export default flowRight(connect(mapRuntimeToProps))(AppMobile);
