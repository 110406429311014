import { isObject, isString } from 'lodash';
import { getImageUrl, urijs } from '@wix/communities-blog-client-common';

export default function resolveUserImage(
  image,
  { type, width = 100, height = 100, quality, format, blur, imageHost } = {},
) {
  if (isObject(image)) {
    return getImageUrl({ imageHost, image, maxWidth: width, maxHeight: height, quality, type, format, blur });
  }

  if (isString(image) && urijs(image).is('relative')) {
    return getImageUrl({
      imageHost,
      image: { file_name: image },
      maxWidth: width,
      maxHeight: height,
      quality,
      type,
      format,
      blur,
    });
  }

  return image;
}
