import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../components/runtime-context';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { MODAL_TYPE_DEMO_MODE } from '@wix/communities-blog-client-common';
import { getIsDemoMode } from '../store/instance-values/instance-values-selectors';
import { getViewMode } from '../store/basic-params/basic-params-selectors';
import createHocName from '../services/create-hoc-name';
import withAuth from './with-auth';

const NAME = 'PreventClickInDemoMode';

export default function preventClickInDemoMode(WrappedComponent) {
  class PreventClickInDemoMode extends Component {
    static displayName = createHocName(NAME, WrappedComponent);

    handleClick = event => {
      const { isDemoMode, viewMode, onClick, openModal } = this.props;

      if (isDemoMode) {
        openModal(MODAL_TYPE_DEMO_MODE, { viewMode });
      } else {
        onClick && onClick(event);
      }
    };

    render() {
      return <WrappedComponent {...this.props} onClick={this.handleClick} />;
    }
  }

  PreventClickInDemoMode.propTypes = {
    isDemoMode: PropTypes.bool.isRequired,
    viewMode: PropTypes.string,
    openModal: PropTypes.func.isRequired,
    onClick: PropTypes.func,
  };

  hoistNonReactStatics(PreventClickInDemoMode, WrappedComponent);

  const mapRuntimeToProps = (state, _ownProps, actions) => ({
    viewMode: getViewMode(state),
    isDemoMode: getIsDemoMode(state),
    openModal: actions.openModal,
  });

  return connect(mapRuntimeToProps)(withAuth(PreventClickInDemoMode));
}
