import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { getIsTemplate } from '@wix/communities-blog-client-common';

import { connect } from '../../components/runtime-context';
import { DEMO_MODE_EDITOR, DEMO_MODE_PREVIEW, DEMO_MODE_SITE } from '../../components/message/message-types';
import messages from '../../components/message/message-map-by-type';
import { getIsDemoMode } from '../../store/instance-values/instance-values-selectors';
import { getViewMode } from '../../store/basic-params/basic-params-selectors';
import Message from '../../components/message';

const getMessageType = viewMode => {
  if (viewMode === 'editor') {
    return DEMO_MODE_EDITOR;
  } else if (viewMode === 'preview') {
    return DEMO_MODE_PREVIEW;
  } else {
    return DEMO_MODE_SITE;
  }
};

const DemoMessageRoot = ({ viewMode, isDemoMode, isTemplate }) => {
  if (isDemoMode && !isTemplate) {
    const messageType = getMessageType(viewMode);
    const message = messages[messageType];
    return <Message message={message} position="static" alignment="center" />;
  }
  return null;
};

DemoMessageRoot.propTypes = {
  viewMode: PropTypes.string.isRequired,
  isDemoMode: PropTypes.bool,
};

const mapRuntimeToProps = state => ({
  viewMode: getViewMode(state),
  isDemoMode: getIsDemoMode(state),
  isTemplate: getIsTemplate(state),
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
)(DemoMessageRoot);
