import { get } from 'lodash';

export const getIsLoaded = state => state.isLoaded;

export const getIsEntityLoaded = (state, entity, id = '') =>
  Boolean(id ? get(getIsLoaded(state), [entity, id]) : getIsLoaded(state)[entity]);

export const getIsCategoryLoaded = (state, id) => getIsEntityLoaded(state, 'category', id);
export const getIsTagLoaded = (state, id) => getIsEntityLoaded(state, 'tag', id);
export const getIsPostLoaded = (state, slug) => getIsEntityLoaded(state, 'post', slug);
export const getIsFeedPostsLoaded = state => getIsEntityLoaded(state, 'feed-posts');
export const getIsRecentPostsLoaded = (state, id) => getIsEntityLoaded(state, 'recent-posts', id);
export const getIsRelatedPostsLoaded = (state, id) => getIsEntityLoaded(state, 'related-posts', id);
export const getIsCommentsLoaded = (state, postId) => getIsEntityLoaded(state, 'comments', postId);
export const getIsSearchLoaded = state => getIsEntityLoaded(state, 'search');
export const getIsPostDraftSaved = (state, draftKey) => getIsEntityLoaded(state, 'postDraft', draftKey);
export const getIsArchiveLoaded = state => getIsEntityLoaded(state, 'archive');
