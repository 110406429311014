import React from 'react';
import { Interpolate } from 'react-i18next';
import Wix from '../../services/wix-sdk-polyfill';
import {
  RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY,
  RCE_FILE_TOO_BIG,
  RCE_FILE_UPLOAD_LIMITED,
  RCE_FILE_UPLOAD_FAILURE,
} from '@wix/communities-blog-client-common';
import {
  CATEGORY_CREATE,
  CATEGORY_DELETE,
  CATEGORY_SUBSCRIBED,
  CATEGORY_UNSUBSCRIBED,
  CATEGORY_UPDATE,
  COMMENT_DELETE,
  COMMENT_UPDATE,
  DEMO_MODE_EDITOR,
  DEMO_MODE_PREVIEW,
  DEMO_MODE_SITE,
  DRAFT_DELETE,
  DRAFT_DUPLICATE,
  DRAFT_MOVE_TO_TRASH,
  LOGO_SAVED,
  MEMBER_BLOCK,
  MEMBER_DEMOTE_WRITER,
  MEMBER_PROMOTE_WRITER,
  MEMBER_UNBLOCK,
  NOT_PUBLISHED_BLOG_POST_PUBLISH,
  POST_CHANGES_DISCARDED,
  POST_CHANGES_NOT_PUBLISHED,
  POST_CHANGE_CATEGORY,
  POST_DELETE,
  POST_DELETE_ANOTHER_WRITER_EDITING_ERROR,
  POST_DISABLE_COMMENTING,
  POST_DUPLICATE,
  POST_ENABLE_COMMENTING,
  POST_MOVE,
  POST_MOVE_TO_TRASH,
  POST_PIN,
  POST_PUBLISH,
  POST_RESTORE,
  POST_REVERTED_TO_DRAFT,
  POST_SUBSCRIBED,
  POST_UNPIN,
  POST_UNSUBSCRIBED,
  POST_UPDATE,
  PROFILE_UPDATE,
  PROFILE_UPDATE_FAILED,
  REQUIRE_CATEGORY_FIELDS,
  REQUIRE_POST_FIELDS,
  REQUIRE_PROFILE_FIELDS,
  SCHEDULE,
  SETTINGS_UPDATED,
  UNSCHEDULE,
  USER_SUBSCRIBED,
  USER_UNSUBSCRIBED,
} from './message-types';

export default {
  [POST_RESTORE]: {
    content: () => <Interpolate i18nKey="messages.post-restored" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-restored',
  },
  [POST_MOVE_TO_TRASH]: {
    content: () => <Interpolate i18nKey="messages.post-moved-to-trash" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-moved-to-trash',
  },
  [POST_PIN]: {
    content: () => <Interpolate i18nKey="messages.post-pinned" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [POST_UNPIN]: {
    content: () => <Interpolate i18nKey="messages.post-unpinned" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [POST_DELETE]: {
    content: () => <Interpolate i18nKey="messages.post-deleted" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-deleted',
  },
  [POST_DELETE_ANOTHER_WRITER_EDITING_ERROR]: {
    content: () => (
      <Interpolate i18nKey="messages.post-delete-another-writer-editing-error" useDangerouslySetInnerHTML={true} />
    ),
    type: 'error',
    messageKey: 'messages.post-delete-another-writer-editing-error',
  },
  [POST_PUBLISH]: {
    content: () => <Interpolate i18nKey="messages.post-published" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-published',
    linkMessageKey: 'messages.post-published-link',
  },
  [POST_REVERTED_TO_DRAFT]: {
    content: () => <Interpolate i18nKey="messages.post-reverted-to-draft" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-reverted-to-draft',
  },
  [POST_CHANGES_DISCARDED]: {
    content: () => <Interpolate i18nKey="messages.post-changes-discarded" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-changes-discarded',
  },
  [POST_CHANGES_NOT_PUBLISHED]: {
    content: () => <Interpolate i18nKey="messages.post-changes-not-published" useDangerouslySetInnerHTML={true} />,
    type: 'info',
    messageKey: 'messages.post-changes-not-published',
  },
  [POST_UPDATE]: {
    content: () => <Interpolate i18nKey="messages.post-updated" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-updated',
  },
  [NOT_PUBLISHED_BLOG_POST_PUBLISH]: {
    type: 'info',
    messageKey: 'messages.not-published-blog-post-published',
  },
  [POST_ENABLE_COMMENTING]: {
    content: () => <Interpolate i18nKey="messages.commenting-enabled" useDangerouslySetInnerHTML={true} />,
    type: 'info',
    messageKey: 'messages.commenting-enabled',
  },
  [POST_DISABLE_COMMENTING]: {
    content: () => <Interpolate i18nKey="messages.commenting-disabled" useDangerouslySetInnerHTML={true} />,
    type: 'info',
    messageKey: 'messages.commenting-disabled',
  },
  [POST_DUPLICATE]: {
    content: () => <Interpolate i18nKey="messages.post-duplicated" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-duplicated',
  },
  [DRAFT_MOVE_TO_TRASH]: {
    content: () => <Interpolate i18nKey="messages.draft-moved-to-trash" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.draft-moved-to-trash',
  },
  [DRAFT_DELETE]: {
    content: () => <Interpolate i18nKey="messages.draft-deleted" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.draft-deleted',
  },
  [DRAFT_DUPLICATE]: {
    content: () => <Interpolate i18nKey="messages.draft-duplicated" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.draft-duplicated',
  },
  [UNSCHEDULE]: {
    content: () => <Interpolate i18nKey="messages.unschedule" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.unschedule',
  },
  [SCHEDULE]: {
    content: (t, props) => <Interpolate i18nKey="messages.schedule" {...props} useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.schedule',
    createVariables: (date, time) => ({
      date: date,
      time: time,
    }),
  },
  [USER_SUBSCRIBED]: {
    content: () => <Interpolate i18nKey="messages.user-subscribed" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [USER_UNSUBSCRIBED]: {
    content: () => <Interpolate i18nKey="messages.user-unsubscribed" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [POST_SUBSCRIBED]: {
    content: () => <Interpolate i18nKey="messages.post-subscribed" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [POST_UNSUBSCRIBED]: {
    content: () => <Interpolate i18nKey="messages.post-unsubscribed" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [POST_MOVE]: {
    content: () => <Interpolate i18nKey="messages.post-moved" useDangerouslySetInnerHTML={true} />,
  },
  [POST_CHANGE_CATEGORY]: {
    content: () => <Interpolate i18nKey="messages.post-category-changed" useDangerouslySetInnerHTML={true} />,
  },

  [COMMENT_UPDATE]: {
    content: () => <Interpolate i18nKey="messages.comment-updated" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [COMMENT_DELETE]: {
    content: () => <Interpolate i18nKey="messages.comment-deleted" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },

  [REQUIRE_POST_FIELDS]: {
    content: (t, props) => {
      const key = props && props.sort().join('-');
      switch (key) {
        case 'title':
          return <Interpolate i18nKey="messages.post-title-required" useDangerouslySetInnerHTML={true} />;
        case 'content':
          return <Interpolate i18nKey="messages.post-content-required" useDangerouslySetInnerHTML={true} />;
        default:
          return <Interpolate i18nKey="messages.post-fields-required" useDangerouslySetInnerHTML={true} />;
      }
    },
    type: 'error',
  },

  [REQUIRE_CATEGORY_FIELDS]: {
    content: (t, props) => {
      const key = props && props.sort().join('-');
      switch (key) {
        case 'label':
          return <Interpolate i18nKey="messages.category-title-required" useDangerouslySetInnerHTML={true} />;
        default:
          return <Interpolate i18nKey="messages.category-fields-required" useDangerouslySetInnerHTML={true} />;
      }
    },
    type: 'error',
  },

  [MEMBER_PROMOTE_WRITER]: {
    content: () => <Interpolate i18nKey="messages.user-promoted-writer" useDangerouslySetInnerHTML={true} />,
  },
  [MEMBER_DEMOTE_WRITER]: {
    content: () => <Interpolate i18nKey="messages.user-demoted-writer" useDangerouslySetInnerHTML={true} />,
  },

  [MEMBER_BLOCK]: {
    content: () => <Interpolate i18nKey="messages.user-blocked" useDangerouslySetInnerHTML={true} />,
  },
  [MEMBER_UNBLOCK]: {
    content: () => <Interpolate i18nKey="messages.user-unblocked" useDangerouslySetInnerHTML={true} />,
  },

  [CATEGORY_DELETE]: {
    content: () => <Interpolate i18nKey="messages.category-deleted" useDangerouslySetInnerHTML={true} />,
    messageKey: 'messages.category-deleted',
  },
  [CATEGORY_CREATE]: {
    content: () => <Interpolate i18nKey="messages.category-created" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.category-created',
  },
  [CATEGORY_UPDATE]: {
    content: () => <Interpolate i18nKey="messages.category-updated" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.category-updated',
  },
  [CATEGORY_SUBSCRIBED]: {
    content: () => <Interpolate i18nKey="messages.category-subscribed" useDangerouslySetInnerHTML={true} />,
  },
  [CATEGORY_UNSUBSCRIBED]: {
    content: () => <Interpolate i18nKey="messages.category-unsubscribed" useDangerouslySetInnerHTML={true} />,
  },

  [REQUIRE_PROFILE_FIELDS]: {
    content: (t, props) => {
      const { name, image, cover } = props;
      if (name) {
        return <Interpolate i18nKey="messages.profile-name-required" useDangerouslySetInnerHTML={true} />;
      }
      if (image) {
        return <Interpolate i18nKey="messages.profile-image-uploading" useDangerouslySetInnerHTML={true} />;
      }
      if (cover) {
        return <Interpolate i18nKey="messages.profile-cover-uploading" useDangerouslySetInnerHTML={true} />;
      }
      return <Interpolate i18nKey="messages.profile-unknown-error" useDangerouslySetInnerHTML={true} />;
    },
    type: 'error',
  },
  [PROFILE_UPDATE]: {
    content: () => <Interpolate i18nKey="messages.profile-updated" useDangerouslySetInnerHTML={true} />,
  },
  [PROFILE_UPDATE_FAILED]: {
    content: () => <Interpolate i18nKey="messages.profile-update-failed" useDangerouslySetInnerHTML={true} />,
    type: 'error',
  },

  [DEMO_MODE_SITE]: {
    content: () => <Interpolate i18nKey="messages.demo-mode-site" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [DEMO_MODE_EDITOR]: {
    content: () => <Interpolate i18nKey="messages.demo-mode-editor" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [DEMO_MODE_PREVIEW]: {
    content: t => (
      <Interpolate
        i18nKey="messages.demo-mode-preview"
        activate={
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => Wix.Preview.openSettingsDialog()}
          >
            {t('messages.demo-mode-preview-activate')}
          </span>
        }
        useDangerouslySetInnerHTML={true}
      />
    ),
    type: 'info',
  },
  [SETTINGS_UPDATED]: {
    content: () => <Interpolate i18nKey="messages.settings-saved" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.settings-saved',
  },
  [LOGO_SAVED]: {
    content: () => <Interpolate i18nKey="messages.logo-saved" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.logo-saved',
  },
  [RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY]: {
    content: () => <Interpolate i18nKey="messages.download-will-begin-shortly" useDangerouslySetInnerHTML={true} />,
    type: 'info',
    messageKey: 'messages.download-will-begin-shortly',
  },
  [RCE_FILE_TOO_BIG]: {
    content: () => <Interpolate i18nKey="messages.file-too-big" useDangerouslySetInnerHTML={true} />,
    type: 'error',
    messageKey: 'messages.file-too-big',
  },
  [RCE_FILE_UPLOAD_LIMITED]: {
    content: () => <Interpolate i18nKey="messages.file-upload-limited" useDangerouslySetInnerHTML={true} />,
    type: 'error',
    messageKey: 'messages.file-upload-limited',
  },
  [RCE_FILE_UPLOAD_FAILURE]: {
    content: () => <Interpolate i18nKey="messages.file-upload-failure" useDangerouslySetInnerHTML={true} />,
    type: 'error',
    messageKey: 'messages.file-upload-failure',
  },
};
